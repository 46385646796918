//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';
import * as api from '$network/api.js';
import logo from '$resources/images/logo-company.png';
import InputText from '$components/ui/input-text.vue';
import UiButton from '$components/ui/button.vue';

export default {
  name: 'Login',
  components: {
    InputText,
    UiButton
  },
  data: () => ({
    user: '',
    password: '',
    logo
  }),
  beforeMount() {
    this.setNavigationMenuStatus(false);
  },
  methods: {
    ...mapMutations('ui', ['setNavigationMenuStatus']),
    ...mapMutations('auth', ['setLoggedStatus']),
    ...mapMutations('common', ['setUser']),
    async login() {
      const { user, password, setLoggedStatus, setUser } = this;

      if (user.length === 0 || password.length === 0) {
        this.$notify.error('El usuario o la contraseña estan vacios.');
        return;
      }

      try {
        const response = await api.login({ identity: user, password });
        setUser(response.data.content.user);
        setLoggedStatus(true);
        this.$router.push('/');
      } catch (error) {
        if (error.response.status === 400) {
          this.$router.push('/');
          return;
        }

        if (error.response.status === 429) {
          this.$notify.error(
            'Demasiados intentos de login consecutivos. Espere 1 minuto o contacte con el administrador'
          );
          return;
        }

        this.$notify.error('El usuario o la contraseña no son correctas.');
      }
    }
  }
};
